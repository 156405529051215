@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face { font-family: 'Nunito'; src: url('../public/Nunito.ttf') format('woff2'); }
body,html {
    background-color: #F2F2F2;
    /* font-family: "Nunito", sans-serif; */
}

html {
    scroll-behavior: smooth;
}